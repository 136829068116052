
          <template>
<v-card
      flat
      :class="userLoggedIn? 'transparent' : 'transparent'"
      
    >

 <v-snackbar v-model="FriendRequestSnackbar" :timeout="4000" top color="success">
      <span>Your friendship request to {{UserData.Full_Name}} has been sent!...</span>
      <v-btn color="white" @click="FriendRequestSnackbar = false">Close</v-btn>
    </v-snackbar>
  <v-bottom-sheet v-model="helpsheet">
      <v-sheet class="text-center" height="200px">
        <v-btn
          class="mt-6"
          dark
          color="red"
          @click="helpsheet = !helpsheet"
        >close</v-btn>
        <div>This is the My Profile help sheet. What would you want to know?</div>
      </v-sheet>
    </v-bottom-sheet>
<div v-if="!BusinessCardType">
 <!-- <v-parallax
            height="170"
            :src="System.Social_Parallax? System.Social_Parallax.path : require('@/assets/RapidappsParallax.jpeg')"
            
            >
             
            </v-parallax>    -->

  <v-layout class="justify-center" style="margin-top:40px;">
      <!-- <v-row class="justify-center"> -->
      
     <v-flex lg8 md10 >
        <v-parallax v-if="!UserData.Parallax"
            height="200"
           :src="System.Social_Parallax? System.Social_Parallax.path : require('@/assets/RapidappsParallax.jpeg')"
            class="mx-3"
            >
             
            </v-parallax>   
            <v-parallax  v-if="UserData.Parallax"
            height="200"
            :src="UserData.Parallax"
            class="mx-3"            
            >
            </v-parallax>
       
          <v-avatar
          style="margin-top:-220px;"
           tile
              color="grey"
              size="164"
              class="mx-10"                     
            >
            <v-img v-if="UserData.Profile_Photo" :src="UserData.Profile_Photo"></v-img>
            <v-img v-if="!UserData.Profile_Photo"  src="@/assets/BlankProfilePic.png"></v-img>
          </v-avatar>
          <v-row class="mb-6 mx-3 justify-center" style="margin-top:-25px;" no-gutters> 
                             
            <v-col cols="12" :xl="3" :lg="3" :md="4" :sm="4" :xs="12" class="primary">
             <v-card-title class="mediumoverline white--text">{{UserData.Full_Name}}</v-card-title>
             <p class="centertext grey--text mx-2 my-2">
               {{UserData.FavoriteQuote}}
              </p>
             <v-card-title class="headline white--text">Skills</v-card-title>
             <v-divider class="pop"></v-divider>
             <v-chip-group
             class="mx-3"
                    active-class="blue--text"
                    column
                    v-if="UserData.tags"
                >
                    <v-chip
                    v-for="tag in UserData.tags"
                    :key="tag"
                    >
                    {{ tag }}
                    </v-chip>
                </v-chip-group>
               <WebFormTab :PageHeadersFont="PageHeadersFont" :PageContentFont="PageContentFont" :RelatedEntity="'groupmemberenquiries'"
          :PageHeadersFontSize="PageHeadersFontSize" @ActivateOptionSetDialog="ActivateOptionSetDialog"
          @ConfirmSubmission="ConfirmSubmission" :PageName="'Group Featured Member - '+$route.query.GroupType" @ProcessingData="ProcessingData"
          :OptionNameSelect="OptionNameSelect" :IsElement="true" :tabindex="0" :rowindex="0"
          :tab="MappedClassifiedTab" :i="0"
          />


         </v-col>
            <v-col cols="12" :xl="9" :lg="9" :md="8" :sm="8" :xs="12">
            <v-card tile  :dark="AppisDarkMode" width="100%" height="100%" class="white text-xs-center" v-if="UserData.WriteupSections">
                  
               <v-spacer></v-spacer> 
                <div v-for="(section,i) in UserData.WriteupSections" :key="section.itemObjKey">
                <v-card-title :style="isOdd(i)? 'background: linear-gradient(300deg,rgba(255,255,255,0) 50%,rgba(255,255,255,1) 90%)' : 'background: linear-gradient(60deg,rgba(255,255,255,0) 5%,rgba(255,255,255,1) 90%)'"
                :class="isOdd(i)? 'justify-end headline background links--text' : 'justify-start headline background links--text'">{{section.Title}}</v-card-title>
               
                    <v-divider :width="isOdd(i)? '100%' : '70%'" class="grey" :inset="isOdd(i)"></v-divider>
                <v-card-text style="padding:20px;" v-for="child in section.Children" :key="child.itemObjKey">
                    
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                               <span>{{child.SubheaderOne}}</span>                             
                            </v-list-item-content>
                            <v-list-item-content>
                                <span>{{child.SubheaderTwo}}</span>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                   <span>{{child.SubText}}</span>                
                </v-card-text>
                </div>
               
              </v-card>
            </v-col>
            
          
          </v-row>
          
      
        <!-- <v-layout class="justify-center mx-3">
        <v-card flat tile class="transparent" width="100%" style="padding-bottom:40px;"
          min-height="500">
        
      </v-card>
        </v-layout> -->
     </v-flex>
  </v-layout>
  
  </div>
<v-layout class="justify-center" v-if="BusinessCardType">
  <v-flex lg8 md10 style="padding-bottom:50px;">
  <v-card
    class="mx-auto"
    width="100%"
    tile
  >
      <v-layout row class="justify-start" style="margin-top:40px;">
         <v-col
                  :xl="3" :lg="3" :md="3" :sm="3" :xs="3"
                  cols="3">
          <v-avatar
              color="grey"
              :size="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs? 100 :150"
              class="mx-10"                     
            >
            <v-img v-if="UserData.Profile_Photo" :src="UserData.Profile_Photo"></v-img>
            <v-img v-if="!UserData.Profile_Photo"  src="@/assets/BlankProfilePic.png"></v-img>
          </v-avatar>
       </v-col>
       <v-col
                  :xl="8" :lg="8" :md="8" :sm="8" :xs="8"
                  cols="6"
                         class="mx-10" style="align-self:center;"                   
                  >
                   <v-divider style="border: 1px solid black;width:40%;margin: auto;"/>
                      <v-card-title class="justify-center mediumoverline" style="color:#000000;text-align: center;align-self:center;">
                      {{UserData.Full_Name}}
                      </v-card-title>
                      
                      <v-divider style="border: 1px solid black;width:40%;margin: auto;"/>
                       <v-card-title v-if="UserData.Position" class="justify-center" style="color:#000000;text-align: center;align-self:center;font-size:14px;">{{System.Name}} - {{UserData.Position}}</v-card-title>
       </v-col>
       
       
  </v-layout>
      <v-divider></v-divider>
      <v-btn v-if="Editing && usercanEdit" @click="UpdateUserValues()">Save</v-btn>
      <v-list
      v-list width="100%"
        nav
        dense
      >
      <v-list-item class="background justify-center">
        <v-chip-group >
          <v-chip :href="'callto:'+UserData.Mobile_Number" color="orange white--text">Call Me</v-chip>
          <v-chip :href="'callto:'+UserData.Mobile_Number" color="green white--text">Whatsapp</v-chip>
        </v-chip-group>
      </v-list-item>
     <v-divider>
      </v-divider>
      <v-list-item-group
        
          color="primary"
        >
      <v-list-item class="justify-center" v-for="link in CardLinks" :key="link.itemObjKey">
        <v-btn v-if="!Editing" :href="'//'+link.URL" width="100%" class="primary">{{link.Name}}</v-btn><v-icon @click="Editing = !Editing" v-if="usercanEdit">mdi-pencil</v-icon>
         <FieldValueEditerComponent @UpdateEditableField="UpdateEditableField" :Record="UserData" :field="link.FullField" @onPhotoFileselected="onPhotoFileselected"
          :CurrentEntity="NavlistEntity" @UploadFileSelect="UploadFileSelect" @SaveDocumentField="SaveDocumentField"
          :SystemEntities="SystemEntities" :PrimaryFieldName="'Full_Name'" :RelatedObj="''" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
            />
        <!-- <span v-if="Editing" :href="'//'+link.URL" width="100%" class="primary">{{link.Name}}</span> -->
      </v-list-item>
      
      </v-list-item-group>
       <v-divider>
      </v-divider>
        <v-list-item-group
        
          color="primary"
        >
         <v-layout class="justify-center" v-if="FinalBUAddress">
            <v-list-item-icon>
              <v-icon>mdi-map-marker</v-icon>
            </v-list-item-icon>
            <v-flex xl6 lg6 md6 sm12 xs12>
              <v-list-item-title class="caption" v-html="FinalBUAddressName"></v-list-item-title>
            </v-flex>
             <v-flex xl6 lg6 md6 sm12 xs12>
               <div class="mapouter">
                <div class="gmap_canvas">
                <iframe width="200" height="170" id="gmap_canvas" style="padding:10px;" :src="FinalBuAddressMapped" frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                </iframe>
            </div></div>
            </v-flex>
        </v-layout>
          <v-list-item :href="'callto:'+UserData.Mobile_Number">
            <v-list-item-icon>
              <v-icon>mdi-cellphone</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{UserData.Mobile_Number}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="UserData.Work_Number" :href="'callto:'+UserData.Work_Number">
            <v-list-item-icon>
              <v-icon>mdi-phone</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{UserData.Work_Number}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="UserData.Fax_Number">
            <v-list-item-icon>
              <v-icon>mdi-fax</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{UserData.Fax_Number}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :href="'mailto:'+UserData.Email">
            <v-list-item-icon>
              <v-icon>mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{UserData.Email}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :href="System.ClientAppURL">
            <v-list-item-icon>
              <v-icon>mdi-web</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{System.ClientAppURL}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>


        <v-divider>
      </v-divider>
      <v-list-item class="background justify-center">
       <v-img height="100" contain src="@/assets/logo.png"/>
      </v-list-item>
     <v-divider>
      </v-divider>
      <v-list-item class="justify-center">
        <v-chip-group>
          <v-icon color="#1773ea">mdi-facebook</v-icon>
          <v-icon color="#0077b0">mdi-linkedin</v-icon>          
        </v-chip-group>
      </v-list-item>
      <v-divider>
      </v-divider>
       <v-list-item class="justify-center">
        <v-btn :href="VCardLink">Download</v-btn>
      </v-list-item>
      </v-list>
  </v-card>
  </v-flex>
</v-layout>

  </v-card>
</template>




<script>
import firebase from 'firebase';
import db from '@/main'
import format from 'date-fns/format'
import WebFormTab from '@/components/WebPages/RenderComponents/WebFormTabReadOnly';
import FieldValueEditerComponent from '@/components/Database/Fields/Input/FieldValueEditerComponent';
export default {
  props: ['AppisDarkMode','System','SystemEntities','Directories'],
  middleware: 'auth',
components: {
  WebFormTab,
  FieldValueEditerComponent
},
  data() {
    return {
      FormProcessing: false,
      AutomationCreatedSnackbar: false,
      AutomationSnackbarDetails: '',
      ClassifiedTab: {
          ButtonAlignment: 'justify-end',
          HeightRestrictions: true,
          HideFormName: true,
          Tile: true,
          BoxTransparency: 'transparent',
        Name: 'Web Form',
        FormName: 'Group Member Enquiry',
        FromDatabase: true,
        DarkForm: true,
        BGColor: {
              hexa: '#ffffff'},
        HeaderColor: {hex: '#ffffff'},
        EntityForm: true,
        RelatedEntity: {id: 'groupmemberenquiries'},
        FormSubmitted:false,

        SingleName:'Group Member Enquiry',
        FormFields: [
  {
    "Type": "Lookup",
    "Name": "Enquirying User",
    "OnWebForm": true,
    "RelatedBuildID": "Users",
    "UsedonForm": true,
    "Primary": false,
    "RelatedRecord": "Users",
    "LookupFieldName": "Full_Name",
    "DisplayName": "Enquirying User",
    "RelatedLocation": "User",
    "id": "Client",
    "IsHeader": true,
    "Created": false,
    StrictlyLoggedIn: true,
    "MappedField": {
      "Primary": false,
      "Name": "Enquirying_User",
      LoggedInUser: true,
      
    }
  },
  {
    "Type": "Lookup",
    "Name": "Group",
    "OnWebForm": true,
    "RelatedBuildID": "Groups",
    "UsedonForm": true,
    "Primary": false,
    "RelatedRecord": "Groups",
    "LookupFieldName": "name",
    "DisplayName": "Group",
    "RelatedLocation": "Group",
    "id": "group",
    "IsHeader": true,
    "Created": false,
    "MappedField": {
      "Primary": false,
      "Name": "group",
      
    }
  },
  {
    "Type": "Common Field",
    "CommonFieldType": "Email",
    "Name": "Email",
    "OnWebForm": true,
    "UsedonForm": true,
    "Primary": false,
    "DisplayName": "Email",
    "IsHeader": true,
    "Created": false,
    "MappedField": {
      "Primary": false,
      "Name": "Email",
      LoggedInUserValue: true,
    }
  },
  {
    "Type": "Option Set",
    "Name": "Group_Type",
    "OnWebForm": true,
    "UsedonForm": true,
    "Primary": false,
    "DisplayName": "Group Type",
    "IsHeader": true,
    "Created": false,
    "MappedField": {
      "Primary": false,
      "Name": "grouptype",
    }
  },
  {
    "Type": "Common Field",
    "CommonFieldType": "Telephone Number",
    "Name": "Mobile_Number",
    "OnWebForm": true,
    "UsedonForm": true,
    "Primary": false,
    "DisplayName": "Mobile Number",
    "IsHeader": true,
    "Created": false,
    "MappedField": {
      "Primary": false,
      "Name": "Mobile_Number",
      LoggedInUserValue: true,
    }
  },
  {
    "DisplayName": "Featured Group Member",
    "RelatedBuildID": "Featured_Group_Members",
    "Type": "Lookup",
    "Created": false,
    "LookupFieldName": "Full_Name",
    "OnWebForm": true,
    "Primary": false,
    "Name": "Featured Group Member",
    "RelatedRecord": "Featured Group Members",
    "UsedonForm": true,
    "RelatedLocation": "Featured Group Member",
    "IsHeader": true,
    "id": "Featured_Group_Member",
    "MappedField": {
      "Name": "Title",
      "Primary": true
    }
  },
  {
    "Type": "Multiple Lines Text",
    "IsHeader": true,
    "id": "Enquiry",
    "Name": "Enquiry",
    "DisplayName": "Enquiry",
    "OnWebForm": true,
    "Primary": false,
    "UsedonForm": true,
    "Created": false
  },
  {
    "Type": "Date",
    "IsHeader": true,
    "id": "Date_Required",
    "Name": "Date_Required",
    "DisplayName": "Date Required",
    "OnWebForm": true,
    "Primary": false,
    "UsedonForm": true,
    "Created": false
  },
],
        StatusDefault: {
  "HasDeepHierarchy": true,
  "Level": {
    "id": 1,
    "FieldName": "Status",
    "children": [
      {
        "FieldName": "Status_Reason",
        "id": 2,
        "children": [],
        "name": "Status Reason"
      }
    ],
    "name": "Status"
  },
  "Name": "Active",
  "LevelFieldName": "Status",
  "ID": 1000001,
  "Options": [
    {
      "Level": {
        "id": 2,
        "children": [],
        "FieldName": "Status_Reason",
        "name": "Status Reason"
      },
      "Name": "Open",
      "LevelFieldName": "Status_Reason",
      "ID": 1000003
    }
  ]
},
        Status_ReasonDefault: {
  "ID": 1000003,
  "Name": "Open",
  "Level": {
    "id": 2,
    "children": [],
    "name": "Status Reason",
    "FieldName": "Status_Reason"
  },
  "LevelFieldName": "Status_Reason"
},
      },
        VCardLink: '',
        BusinessCardType: false,
        Editing: false,
        EditingFeaturedMember: false,
        UserisFeaturingGroupAdmin: false,
        
        VCardLink: '',
        BusinessCardType: false,
        EditingFeaturedMember: false,
        UserisFeaturingGroupAdmin: false,
        FriendRequestSnackbar: false,
        RequestStatus: '',
        RelationtoRequest: '',
        RequestStatusMessage: '',
        RequestStatusIcon: '',
        MySentFriendshipRequests: [],
        MyReceivedFriendshipRequests: [],
        MyFriendships: [],
        IsFriend: false,
        ExistingRelationship: '',
      rules: {
          min8Chars: value => value.length >= 8 || "Min. 8 characters",
          required: value => !!value || "Required.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          }
        },
        
      UserData: {
          tags: [],
          WriteupSections: []
      },
      
    }
  },

  created(){
    if(this.$route.name === 'BusinessMember'){
      this.BusinessCardType = true
    }
    

    this.GetRequestingUser()
    
    // window.addEventListener('scroll', this.handleScroll)

  },
  computed: { 
    GroupCategoryTypes(){
      return this.System.Group_Categories ? this.System.Group_Categories.Options : []
    },
    CardLinks(){
      return this.NavlistFields ? this.NavlistFields.map(field => {
        let fieldobj = {
         Name: field.DisplayName,
         URL: this.UserData[field.Name] ,
         FullField: field,
        }
        return fieldobj
      }) : []
    },
    NavlistEntity(){
      return this.Directories.find(obj => obj.id === 'Business_Members')
    },
    NavlistFields(){
      return this.NavlistEntity ? this.NavlistEntity.AdditionalFields : []
    },
    usercanEdit(){
      return this.userIsAdmin
    },
    userLoggedIn () {
      return this.$store.getters.user
    },
    userIsAdmin () {
    return this.$store.state.IsAdmin
  },
    MappedClassifiedTab(){
      this.ClassifiedTab.FormFields = this.ClassifiedTab.FormFields
      .filter(field => {  
        return this.userLoggedIn?  field : !field.StrictlyLoggedIn    
      })
      .map(field => {
        if(field.MappedField){
          let mappedfield = field.MappedField.Name
          //alert(mappedfield+'id')
          if(field.Type === 'Lookup'){
            field.Array = []
            if(!field.MappedField.Primary && !field.MappedField.LoggedInUser){
              
             field.Response = this.UserData[mappedfield]
            }
           
            
            else if(!field.MappedField.Primary && field.MappedField.LoggedInUser && this.userLoggedIn){
             field.Response = {
              Full_Name: this.userLoggedIn.Full_Name,
              id: this.userLoggedIn.id,
            } 
            }
            else{
              field.Response = {
              [field.LookupFieldName]: this.UserData.Full_Name,
              id: this.$route.params.id
              }                 
            }
            
            field.Array.push(field.Response )
          }
          else if(field.MappedField && field.MappedField.LoggedInUserValue && this.userLoggedIn){
            console.log('this.userLoggedIn',this.userLoggedIn)
            field.Response = this.userLoggedIn[field.MappedField.Name]
          }
          else if(field.Type !== 'Date'){
            field.Response = this.UserData[mappedfield]
          }
          
          else{
            field.Response = this[mappedfield].toDate()
          }
          if(field.Type === 'Option Set'){
            field.Options = [this.UserData[mappedfield]]
          }
        }
        return field
      })
      
  
        this.ClassifiedTab.DefaultOwner = {id: this.$route.params.id, Full_Name: this.UserData.Full_Name}
        this.ClassifiedTab.DefaultOwner.Email = this.UserData.Email
      
      
      return this.ClassifiedTab
    },
    FinalBuAddressMapped(){
      return this.FinalBUAddress+'&t=&z=13&ie=UTF8&iwloc=&output=embed'
    },
    FinalBUAddress(){
    let combinedlink = this.UserData.AddressLine1+'+'+this.UserData.AddressLine2+'+'+this.UserData.AddressLineCity+'+'+this.UserData.AddressLinePostalCode+'+'+this.UserData.AddressLineProvince+'+'+this.UserData.AddressLineCountry
    let FinalLink = 'https://maps.google.com/?q='+combinedlink.split(' ').join('+')
    return FinalLink
    },
    FinalBUAddressName(){
      return `<p>`+this.UserData.AddressLine1+`<br>
      `+this.UserData.AddressLine2+`<br>
      `+this.UserData.AddressLineCity+`<br>
      `+this.UserData.AddressLinePostalCode+`<br>
      `+this.UserData.AddressLineProvince+`<br>
      `+this.UserData.AddressLineCountry+`</p>`
    
    },

   
    },
  methods: {
    UpdateUserValues(){
      this.NavlistFields.map(field => {
        if(typeof this.UserData[field.Name] !== 'undefined'){
          db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessmembers').doc(this.$route.params.id).update({
            [field.Name]: this.UserData[field.Name]
          })
        }
      })
    },
    ProcessingData(boolean){
            this.FormProcessing = boolean
        },
      ConfirmSubmission(tab,obj,docid){
            console.log(tab,obj,docid)
            tab.FormSubmitted = true
            this.AutomationCreatedSnackbar = true
            this.AutomationSnackbarDetails = `Awesome! The new record is added. You can view the newly created record here`
        },
    CreateVCard(member){      
         let item = `BEGIN:VCARD%0D%0AVERSION:3.0%0D%0AN;CHARSET=utf-8:`+member.Surname.split(' ').join('%20')+`;`+member.Name.split(' ').join('%20')+`%0D%0AFN;CHARSET=utf-8:`+member.Name.split(' ').join('%20')+`%20`+member.Surname.split(' ').join('%20')
         if(member.Position){
           item = item+`%0D%0AORG;CHARSET=utf-8:Company%0D%0ATITLE;CHARSET=utf-8:`+member.Position.split(' ').join('%20')
         }
         if(member.AddressLine1 && member.AddressLine2 && member.AddressLineCity && member.AddressLinePostalCode && member.AddressLineProvince && member.AddressLineCountry){
 
           item = item+`%0D%0AADR;WORK;PREF;CHARSET=utf-8:;;`+member.AddressLine1.split(' ').join('%20')+`;`+member.AddressLineCity.split(' ').join('%20')+`;`+member.AddressLineProvince.split(' ').join('%20')+`;`+member.AddressLinePostalCode.split(' ').join('%20')+`;`+member.AddressLineCountry.split(' ').join('%20')
         }
         if(member.Email){
           item = item+`%0D%0AEMAIL:`+member.Email
         }
         if(member.Work_Number){
           item = item+`%0D%0ATEL;TYPE=WORK,VOICE:`+member.Work_Number
         }
         if(member.Mobile_Number){
           item = item+`%0D%0ATEL;TYPE=CELL,VOICE:`+member.Mobile_Number
         }
         if(member.Fax_Number){
           item = item+`%0D%0ATEL;TYPE=WORK,FAX:`+member.Mobile_Number
         }
         if(member.Birthday){
           let birthdayjava = member.Birthday.toDate()
           let cardbday = format(birthdayjava,'yyyy-MM-dd')
           item = item+`%0D%0ABDAY;VALUE=DATE:`+cardbday
         }
         
         item = item+`%0D%0AEND:VCARD`
         console.log(item)
      return item
       },

    isOdd(num) {
      return num % 2;
      },
    IntranetViewToggle(boolean){
        this.$emit('IntranetViewToggle',boolean)
      },
      
    DateFormatter(date){
      let yearstring = date.split('-')[0]
        let monthstring = date.split('-')[1]
        let daystring = date.split('-')[2]
        let yearnumber = Number(yearstring)
        let monthnumber = Number(monthstring)
        let daynumber = Number(daystring)
        return new Date(yearnumber, monthnumber-1, daynumber)
    },
    
    handleScroll(event) {
      var mybutton = document.getElementById("myBtn");
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybutton.style.display = "block";
      }
      else {
        mybutton.style.display = "none";
      }
    },

    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    ToggleHelp(){
    this.helpsheet = true
    },
    
      
    ResetFilters() {},
    
    ResetSearch() {
      this.search = ''
    },
  

      
    async GetRequestingUser(){
        var vm = this;
      await firebase.auth().onAuthStateChanged(function(user) {
        if (user) {
            vm.IntranetViewToggle(true)
          user.getIdTokenResult().then(IdTokenResult => {
              console.log('UsersWantsEmailsOnAllNotifications')
              console.log(IdTokenResult.claims.UsersWantsEmailsOnAllNotifications)
              vm.UsersWantsEmailsOnAllNotifications = IdTokenResult.claims.UsersWantsEmailsOnAllNotifications
            })
            db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('users').doc(user.uid).onSnapshot(snapshot => {
                console.log(typeof snapshot.data)
              var userdetails = snapshot.data()

              vm.UserRecord = userdetails
              vm.UserRecord.id = user.uid
        //       MyFriendships: [],
        // IsFriend
                //vm.DetermineFriendshipStatus()
                if(vm.UserRecord !== 'undefined' && !vm.BusinessCardType){
                    vm.GetUser()
                }
                else{
                  vm.GetBusinessUser()
                }
            })
           
          }
      else {
        if(vm.$route.name === 'BusinessMember'){
        vm.GetBusinessUser()
        }
        else{
            vm.RoutetoLoginPage()
        }
          }
          })
      },
      
      
      RoutetoLoginPage(){
        this.$router.push('/login')
      },
      RoutetoErrorPage(){
        this.$router.push('/PermissionError')
      },
      
      
      GetBusinessUser() {
          let vm = this
          db.collection('SystemConfig').doc(this.$store.state.ActiveSuiteid).collection('businessmembers').doc(this.$route.params.id).onSnapshot(snapshot => {
            let userdata = snapshot.data()
            this.UserData = userdata
            let vcard = vm.CreateVCard(userdata)
            this.VCardLink = 'data:text/vcard;charset=UTF-8,'+vcard
            console.log(this.VCardLink)
          })
        },
      
        GetUser() {
          let vm = this
          let grouptypeid = this.GroupCategoryTypes.find(obj => obj.Name === this.$route.query.GroupType.split('-').join(' '))
          let grouptypeidstring = ''+grouptypeid.ID
            db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('featuredgroupmembers').doc(this.$route.params.id).collection('grouptypes').doc(grouptypeidstring).onSnapshot(featured => {
                let featuredmember = featured.data()
                this.UserData = featuredmember
                this.UserData.tags = featuredmember.tags
                this.UserData.WriteupSections = featuredmember.WriteupSections
                db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groups').doc(featuredmember.groupid).onSnapshot(groupsnap => {
                    let groupdata = groupsnap.data()
                    db.collection('SocialNetworks').doc(this.$store.state.ActiveSuiteid).collection('groupmembers').doc(this.UserRecord.id).collection('groups').doc(featuredmember.groupid).onSnapshot(groupmembersnap => {
                      let groupmemberdata = groupmembersnap.data()
                      if(groupmemberdata && groupmemberdata.Administrator){
                          this.UserisFeaturingGroupAdmin = true                          
                      }
                    })
                })
                console.log(this.UserData,this.UserData.tags)
            })
          
        },
        
      
      
    
  

      
        

  }
}


</script>

<style>
/* .borderthicknobottom{
  border-top: 3px solid #35353b;
  border-left: 3px solid #35353b;
  border-right: 3px solid #35353b;
}
.borderthicknotop{
  border-bottom: 3px solid #35353b;
  border-left: 3px solid #35353b;
  border-right: 3px solid #35353b;
} */
.outline {
  margin: 30px;
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
  outline-offset: 15px;
}
.notesoutline{


  background-color: yellow;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.basicoutlined{
  background: #f8f8f8;
  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.newbasicoutlined{

  border: 1px solid #dedee9;
  outline: 1px solid #dedee9;
}
.notestitle{
  background-color: rgb(218, 218, 84);

}
.outlinefont{
  color: red
}

.unpermitted .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: rgb(0, 153, 255);
  color: white;
  text-align: center;
  border-radius: 6px;
  font-size: 15px;
  padding: 15px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 100;
}

.unpermitted:hover .tooltiptext {
  visibility: visible;
}

.banner {
  z-index: 200;
}
.taskdetails{
  font-size: 12px
}
.subheading{
  font-size: 12px
}
.notes{
  font-size: 12px
}
.hasBG{
background-image: 'url(' + require('@/assets/logo.png') + ')';
 background-color: #cccccc;
}
.gmap_canvas {overflow:hidden;background:none!important;height:170px;width:200px;}
.mapouter{position:relative;text-align:right;height:170px;width:200px;}
</style>
    

    